<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>组合项目管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div>
          <div style="display:flex;marginBottom:20px">
            <div style="display:flex;align-items:center;">
              <div style="width:140px;">组合项目名称</div>
              <el-input v-model.trim="groupProjectName" placeholder="请输入组合项目名称" />
            </div>
            <div style="display:flex;align-items:center;margin-left:80px;">
              <div style="width:75px;">创建日期</div>
              <el-date-picker value-format="YYYY-MM-DD" v-model="publishDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div style="display:flex;position: relative">
            <div style="display:flex;align-items:center;">
              <div style="width:140px;">当前持仓股票</div>
              <el-input v-model.trim="stockName" placeholder="请输入股票名称" />
            </div>
            <div style="display:flex;align-items:center;margin-left:80px;">
              <div style="width:75px;">组合状态</div>
              <el-select v-model="groupStates" placeholder="请选择组合状态" style="width: 240px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="search-btn" @click="search">筛选</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">组合项目列表</div>
        <div class="header__btn" @click="addProject">新增组合项目</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
          <el-table-column prop="createTime" label="创建时间" />
          <el-table-column prop="groupProjectName" label="组合项目名称" />
          <el-table-column prop="totalYield" label="总收益率">
            <template #default="scope">
              <div :style="{color:scope.row.totalYield>0?'#FF5555':'#368B11'}"><span v-show="+scope.row.totalYield>0">+</span>{{scope.row.totalYield?scope.row.totalYield:'--'}}<span v-show="scope.row.totalYield">%</span></div>
            </template>
          </el-table-column>
          <el-table-column prop="dailyReturns" label="当日收益率">
            <template #default="scope">
              <div :style="{color:scope.row.dailyReturns>0?'#FF5555':'#368B11'}"><span v-show="+scope.row.dailyReturns>0">+</span>{{scope.row.dailyReturns?scope.row.dailyReturns:'--'}}<span v-show="scope.row.dailyReturns">%</span></div>
            </template>
          </el-table-column>
          <el-table-column prop="currentStockPosition" label="当前持仓股票" />
          <el-table-column prop="currentPosition" label="当前仓位">
            <template #default="scope">
              <div>{{scope.row.currentPosition?scope.row.currentPosition:'--'}}<span v-show="scope.row.currentPosition">%</span></div>
            </template>
          </el-table-column>
          <el-table-column prop="stats" label="组合状态">
            <template #default="scope">
              <div>{{scope.row.stats==='0'?'正常':'结束'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="showDetail(scope.row)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      groupProjectName: '',
      stockName: '',
      groupStates: '0',
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      updateMethod: '',
      options: []
    })

    onMounted(() => {
      getList()
      groupStatesEnum()
    })

    const search = () => {
      getList()
    }

    const groupStatesEnum = () => {
      proxy.$get('/cts/back/projectPortfolioManagement/groupStatesEnum').then((res) => {
        if (res.resultState === '200') {
          state.options = res.data
        }
      }).catch((err) => {

      });
    }
    const getList = () => {
      let data = {
        endDta: state.publishDate ? state.publishDate[1] || '' : '',
        groupProjectName: state.groupProjectName,
        groupStates: state.groupStates,
        pageNo: state.currentPage,
        pageSize: state.pageSize,
        startDta: state.publishDate ? state.publishDate[0] || '' : '',
        stockName: state.stockName
      }
      proxy.$post('/cts/back/projectPortfolioManagement/informationScreening', data).then((res) => {
        if (res.resultState === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
    //   if (val >= state.total) {
        state.currentPage = 1
    //   }
      getList();
    }

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    const addProject = () => {
      router.push({ path: '/projectManagement/add' })
    }

    const showDetail = (data) => {
      router.push({ path: '/projectManagement/detail', query: { id: `${data.groupId}`, groupProjectName: `${data.groupProjectName}` } })
    }

    const formatterTime = (row, column) => {
      let time = row.releaseDate;
      let month = '', date = '';
      if (time[1] < 10) {
        month = `0${time[1]}`
      } else {
        month = `${time[1]}`
      }
      if (time[2] < 10) {
        date = `0${time[2]}`
      } else {
        date = `${time[2]}`
      }

      return `${time[0]}-${month}-${date}`
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      addProject,
      showDetail,
      formatterTime
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
</style>